<template>
    <page title="学生点名" class="class-face-page" :hasBack="true" textAlign="left">
        <van-search v-model="searchValue" class="search" placeholder="请输入学生姓名查询吧..." />
        <div class="status-filter">
            <div
                class="status-btn"
                @click="changeStatus(item.type)"
                v-for="item in statusList"
                :key="item.name"
                :class="{ active: statusActive === item.type }"
            >
                {{ item.name }}
            </div>
        </div>
        <van-list
            @load="onLoad"
            v-model:loading="loading"
            :finished="finished"
            finished-text="已经到底了"
            :offset="50"
            :immediate-check="false"
            v-if="!faceListHasLoad || faceList.length"
        >
            <van-row gutter="30">
                <van-col span="8" v-for="item in faceList" :key="item.id">
                    <div class="face-item">
                        <div class="avatar-wrapper">
                            <img class="avatar" :src="item.avatar" alt="" />
                            <div
                                class="status"
                                v-if="item.status"
                                :style="{ background: convertStatus(item.status).color }"
                            >
                                {{ convertStatus(item.status).text }}
                            </div>
                        </div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                </van-col>
            </van-row>
        </van-list>
        <div v-else class="empty-wrapper">
            <empty />
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import Empty from '@/components/empty/Empty';
import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
import { List, Col, Row, Search } from 'vant';
import { reactive, ref } from 'vue';
export default {
    name: 'ClassFace',
    components: {
        [Page.name]: Page,
        [List.name]: List,
        [Empty.name]: Empty,
        [Col.name]: Col,
        [Row.name]: Row,
        [CommonBlock.name]: CommonBlock,
        [Search.name]: Search,
    },
    setup() {
        const faceList = reactive([
            { id: 1, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
            { id: 2, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 2 },
            { id: 3, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 3 },
            { id: 4, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦' },
            { id: 5, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
            { id: 6, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
            { id: 7, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
            { id: 8, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
            { id: 9, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
            { id: 10, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
        ]);
        const faceListHasLoad = ref(true);
        const loading = ref(false);
        const finished = ref(false);
        const onLoad = () => {
            console.log('onload');
        };
        const convertStatus = (status) => {
            if (status === 1) {
                return {
                    text: '走读',
                    color: '#EE8A32',
                };
            } else if (status === 2) {
                return {
                    text: '住读',
                    color: '#07C160',
                };
            } else if (status === 3) {
                return {
                    text: '全天开放',
                    color: '#FF467F',
                };
            }
        };
        const statusList = [
            { name: '全部', type: '' },
            { name: '未到校', type: 1 },
            { name: '已到校', type: 2 },
            { name: '已请假', type: 3 },
        ];
        const statusActive = ref('');
        const changeStatus = (status) => {
            statusActive.value = status;
        };
        const searchValue = ref('');
        return {
            faceList,
            faceListHasLoad,
            finished,
            loading,
            onLoad,
            convertStatus,
            statusList,
            statusActive,
            changeStatus,
            searchValue,
        };
    },
};
</script>
<style lang="less" scoped>
.status-filter {
    margin: 21px 0 19px 0;
    display: flex;
    justify-content: space-around;
    .status-btn {
        height: 25px;
        border-radius: 13px;
        line-height: 25px;
        font-size: 15px;
        font-family: PingFang SC, serif;
        width: 60px;
        text-align: center;
        color: #999999;
        border: 1px solid #999999;
        &.active {
            background: #07c160;
            color: #fff;
            border: none;
        }
    }
}
.class-face-page {
    padding-bottom: 20px;
    box-sizing: border-box;
}
.face-item {
    margin-bottom: 25px;
}
.avatar-wrapper {
    height: 141px;
    background: #e2e2e2;
    border-radius: 5px;
    position: relative;
    .avatar {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    .status {
        position: absolute;
        border-radius: 5px 0px 5px 0px;
        height: 21px;
        line-height: 21px;
        padding: 0 9px;
        box-sizing: border-box;
        bottom: 0;
        right: 0;
        font-size: 12px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #ffffff;
    }
}
.name {
    font-size: 15px;
    font-family: PingFang SC, serif;
    font-weight: bold;
    line-height: 17px;
    color: #333333;
    text-align: center;
    margin-top: 6px;
}
.empty-wrapper {
    height: 350px;
}
</style>
